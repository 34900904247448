import Vue from "vue";
import App from "./App.vue";
import "@agyletime/wfm-vue-common";
import "almendra-components/dist/almendra.css";

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
