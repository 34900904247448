<template>
  <div class="wrapper">
    <Header />
    <div class="body">
      <LoginForm />
    </div>
    <Footer />
  </div>
</template>

<script>
import { Header, Footer } from "@agyletime/wfm-vue-common";
import LoginForm from "./LoginForm.vue";

export default {
  name: "LoginPage",
  components: {
    LoginForm,
    Header,
    Footer,
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.body {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  min-width: 50%;
}

@media (min-width: 768px) {
  .body {
    margin-left: auto;
    margin-right: auto;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .body {
    min-width: 30%;
    max-width: 30%;
  }
}
</style>
